import { createSelector } from '@ngrx/store';
import { getDemoRegistrationState } from '../reducers';

export const getDemoRegistrationFormState = createSelector(
  getDemoRegistrationState,
  (state) => state.demoRegistrationForm
);

export const getToken = createSelector(
  getDemoRegistrationFormState,
  ({ token }) => token
);

export const getQcCode = createSelector(
  getDemoRegistrationFormState,
  ({ qcCode }) => qcCode
);

export const getMessage = createSelector(
  getDemoRegistrationFormState,
  ({ message }) => message
);

export const getShowMessageStatus = createSelector(
  getDemoRegistrationFormState,
  ({ showMessage }) => showMessage
);
