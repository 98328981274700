import { createAction, props } from '@ngrx/store';
import { Person } from '@qivos/qivoscloud-ng-api';

export const Token = createAction(
  '[Demo Registration Form]: Token',
  props<{ token: string }>()
);

export const AuthByToken = createAction(
  '[Demo Registration Form]: Auth By Token',
  props<{ token: string }>()
);

export const AuthByTokenSuccess = createAction(
  '[Demo Registration Form]: Auth By Token Success',
  props<{ response: any }>()
);

export const AuthByTokenFailed = createAction(
  '[Demo Registration Form]: Auth By Token Failed',
  props<{ error: string }>()
);

export const InitMobileNumber = createAction(
  '[Demo Registration Form]: Initialise Mobile Number',
  props<{ mobileNumber: string }>()
);

export const UpdatePersonData = createAction(
  '[Demo Registration Form]: Update Person Data',
  props<{ person: Person }>()
);

export const UpdatePersonDataSuccess = createAction(
  '[Demo Registration Form]: Update Person Data Success',
  props<{ response: any }>()
);

export const UpdatePersonDataFailed = createAction(
  '[Demo Registration Form]: Update Person Data Failed',
  props<{ error: string }>()
);

export const InitQCCode = createAction(
  '[Demo Registration Form]: Initialise QC Code',
  props<{ qcCode: string }>()
);
