import { Action, select, Selector } from '@ngrx/store';
import { first, Observable } from 'rxjs';

export class FacadeService {
  constructor(public store: any) {}

  dispatch(action: Action) {
    this.store.dispatch(action);
  }

  storeSelect<S = any>(selector: Selector<object, S>) {
    return this.store.pipe(select(selector)) as Observable<S>;
  }

  protected storeSnapshot<S = any>(
    selector: Selector<object, S> | Observable<S>
  ) {
    let snapShot: any;
    const stream: Observable<S> =
      selector instanceof Observable
        ? selector
        : this.store.pipe(select(selector));
    stream.pipe(first()).subscribe((data) => (snapShot = data));
    return snapShot as S;
  }
}
