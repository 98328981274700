import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { FacadeService } from './facade.service';
import { DemoRegistrationFormState } from '../reducers/demo-registration-form.reducer';
import * as selectors from '../selectors/demo-registration-form.selectors';

@Injectable({
  providedIn: 'root',
})
export class DemoRegistrationFormFacadeService extends FacadeService {
  constructor(public override store: Store<DemoRegistrationFormState>) {
    super(store);
  }

  // OBSERVABLES

  token$ = this.storeSelect(selectors.getToken);
  demoRegistrationForm$ = this.storeSelect(
    selectors.getDemoRegistrationFormState
  );
  qcCode$ = this.storeSelect(selectors.getQcCode);
  showMessageStatus$ = this.storeSelect(selectors.getShowMessageStatus);
  message$ = this.storeSelect(selectors.getMessage);

  // OBSERVABLE'S VALUES

  get token() {
    return this.storeSnapshot(this.token$);
  }

  get demoRegistrationForm() {
    return this.storeSnapshot(this.demoRegistrationForm$);
  }

  get qcCode() {
    return this.storeSnapshot(this.qcCode$);
  }
}
