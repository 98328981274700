import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { PersonDetailsFacadeService } from '@qivos/qivoscloud-ion-components';
import {
  Address,
  Email,
  Person,
  QivosCloudService,
  Consent,
} from '@qivos/qivoscloud-ng-api';
import { environment } from 'src/environments/environment';
import {
  AuthByToken,
  InitMobileNumber,
  InitQCCode,
  Token,
  UpdatePersonData,
} from './actions/demo-registration-form.actions';
import { DemoRegistrationFormFacadeService } from './services/demo-registration-form.facade.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(
    public PersonFacade: PersonDetailsFacadeService,
    public qcService: QivosCloudService,
    public DemoRegistrationFormFacade: DemoRegistrationFormFacadeService
  ) {}

  ngOnInit() {
    const token = this.getToken();
    this.DemoRegistrationFormFacade.dispatch(Token({ token }));
    this.DemoRegistrationFormFacade.dispatch(AuthByToken({ token }));
    const person = this.qcService.getDecodedToken();

    if (person !== null) {
      this.DemoRegistrationFormFacade.dispatch(
        InitMobileNumber({ mobileNumber: person.mobileNumber })
      );

      this.DemoRegistrationFormFacade.dispatch(
        InitQCCode({ qcCode: person.personQCCode })
      );
    }
  }

  environment = environment;
  person = new Person();

  register() {
    if (this.PersonFacade.email.length > 0) {
      this.person.emailList?.push(
        new Email({ emailAddress: this.PersonFacade.email, isPrimary: true })
      );
    }

    if (this.PersonFacade.selectedRegionCode.length > 0) {
      this.person.addressList?.push(
        new Address({
          addressLine2: '',
          addressType: 'PRIMARY_PERSON_ADDRESS',
          town: this.PersonFacade.selectedTown,
          regionCode: this.PersonFacade.selectedRegionCode,
          countryCode: 'gr',
        })
      );
    }

    if (this.PersonFacade.smsConsent === true) {
      this.person.consentList?.push(
        new Consent({
          name: 'SMS',
          flag: true,
        })
      );
    }

    if (this.PersonFacade.emailConsent === true) {
      this.person.consentList?.push(
        new Consent({
          name: 'EMAIL',
          flag: true,
        })
      );
    }

    if (this.PersonFacade.viberConsent === true) {
      this.person.consentList?.push(
        new Consent({
          name: 'VIBER',
          flag: true,
        })
      );
    }

    if (this.PersonFacade.multiConsentsStatus === true) {
      this.person.consentList?.push(
        new Consent({
          name: 'SMS',
          flag: true,
        }),
        new Consent({
          name: 'EMAIL',
          flag: true,
        }),
        new Consent({
          name: 'VIBER',
          flag: true,
        })
      );
    }

    this.person.firstName = this.PersonFacade.firstName;
    this.person.lastName = this.PersonFacade.lastName;
    this.person.salutationCode = this.PersonFacade.gender.Value;
    this.person.gender = this.PersonFacade.gender.Value;
    this.person.dateOfBirth = this.PersonFacade.dateOfBirth;
    this.person.nameDayDay = this.PersonFacade.nameDateDay;
    this.person.nameDayMonth = this.PersonFacade.nameDateMonth;
    this.person.QCCode = this.DemoRegistrationFormFacade.qcCode;

    this.DemoRegistrationFormFacade.dispatch(
      UpdatePersonData({ person: this.person })
    );
  }

  getToken() {
    let url = window.location.href;
    let token = url.split('token=')[1];
    return token;
  }
}
