import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';
import { MessageComponent } from './message.component';

@NgModule({
  imports: [CommonModule, FormsModule, IonicModule],
  exports: [MessageComponent],
  declarations: [MessageComponent],
})
export class MessageModule {}
