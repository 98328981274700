import { Component, OnInit } from '@angular/core';
import { DemoRegistrationFormFacadeService } from 'src/app/services/demo-registration-form.facade.service';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss'],
})
export class MessageComponent {
  constructor(
    public demoRegistrationFormFacade: DemoRegistrationFormFacadeService
  ) {}
}
