import { CommonModule, DatePipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule, isDevMode } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { IonicModule } from '@ionic/angular';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { QcComponentsModule } from '@qivos/qivoscloud-ion-components';
import { QcApiModule, QivosCloudService } from '@qivos/qivoscloud-ng-api';
import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

const matModules = [
  MatIconModule,
  MatButtonModule,
  MatFormFieldModule,
  MatInputModule,
];

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    CommonModule,
    BrowserAnimationsModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    QcComponentsModule,
    StoreModule.forRoot({}, {}),
    EffectsModule.forRoot([]),
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: !isDevMode() }),
    HttpClientModule,
    QcApiModule,
    matModules,
  ],
  providers: [
    { provide: 'qivosApiURL', useValue: environment.qivosApiURL },
    {
      provide: 'qcURL',
      useValue: environment.qcURL,
    },
    {
      provide: 'qivosApiURL',
      useValue: environment.qivosApiURL,
    },
    {
      provide: 'clientName',
      useValue: environment.clientName,
    },
    {
      provide: 'qivosRestURL',
      useValue: environment.qivosRestURL,
    },
    QivosCloudService,
    DatePipe,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
