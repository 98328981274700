<ion-content [fullscreen]="true">
  <div
    *ngIf="(DemoRegistrationFormFacade.showMessageStatus$ | async) === false"
    class="wrapper-form"
  >
    <img class="logo" src="../../../assets/icon/Padu_logo.png" />
    <ion-qc-person-details-form
      [personDetails]="DemoRegistrationFormFacade.demoRegistrationForm"
    ></ion-qc-person-details-form>

    <ion-row
      ><ion-button fill="outline" (click)="register()"
        >Register</ion-button
      ></ion-row
    >
  </div>

  <div *ngIf="DemoRegistrationFormFacade.showMessageStatus$ | async">
    <app-message></app-message>
  </div>
</ion-content>
