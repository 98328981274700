import { createReducer, on } from '@ngrx/store';
import {
  Token,
  InitMobileNumber,
  InitQCCode,
  UpdatePersonDataSuccess,
  UpdatePersonDataFailed,
  UpdatePersonData,
} from '../actions/demo-registration-form.actions';
import { PersonDetailsForm } from '@qivos/qivoscloud-ion-components/lib/models/person-details.model';

export interface DemoRegistrationFormState extends PersonDetailsForm {
  token: string;
  qcCode: string;
  message: string;
  showMessage: boolean;
}

const today = new Date();

const minValidDate = new Date();
minValidDate.setFullYear(today.getFullYear() - 18);
const dateOfBirthMaxValue = minValidDate.toISOString();

const maxValidDate = new Date();
maxValidDate.setFullYear(today.getFullYear() - 95);
const dateOfBirthMinValue = maxValidDate.toISOString();

const initialState: DemoRegistrationFormState = {
  token: '',
  qcCode: '',
  mobileNumber: {
    rendered: true,
    required: false,
    label: 'Mobile phone number',
    placeholder: 'Please add your mobile phone number.',
    value: '',
    validation: /^69[0-9]*\d*$/,
    message:
      'The phone number doesn’t exist. Please write a correct phone number.',
    disabled: false,
  },
  gender: {
    rendered: true,
    required: false,
    label: 'Gender',
    maleGenderLabel: 'Male',
    femaleGenderLabel: 'Female',
    value: { Name: '', Value: '' },
  },
  firstName: {
    rendered: true,
    required: false,
    label: 'First Name',
    placeholder: 'Please write your first name.',
    value: '',
    validation: /^[α-ωίϊΐόάέύϋΰήώΑ-ΩΆΈΊΌΎΏΉa-zA-Zs.-]+$/,
    message: 'Please write a correct first name.',
  },
  lastName: {
    rendered: true,
    required: false,
    label: 'Last Name',
    placeholder: 'Please write your last name.',
    value: '',
    validation: /^[α-ωίϊΐόάέύϋΰήώΑ-ΩΆΈΊΌΎΏΉa-zA-Zs.-]+$/,
    message: 'Please write a correct last name.',
  },
  email: {
    rendered: true,
    required: false,
    label: 'Email',
    placeholder: 'Please write a correct last name.',
    value: '',
    validation:
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+(@[A-Za-z0-9.-]{2,})+\.[A-Za-z]{2,}/,
    message: 'The email doesn’t exist. Please write a correct email.',
  },
  region: {
    rendered: true,
    required: false,
    label: 'Region',
    placeholder: 'Please write your region.',
    value: '',
    message: 'Please write a correct region.',
  },
  town: {
    rendered: true,
    required: false,
    label: 'Town',
    placeholder: 'Please write your town.',
    value: '',
  },
  dateOfBirth: {
    rendered: true,
    required: false,
    label: 'Date of birth',
    placeholder: 'Please write your date of birth.',
    datePickerDoneButtonLabel: 'Ok',
    datePickerCancelButtonLabel: 'Cancel',
    value: '',
    minValue: dateOfBirthMinValue,
    maxValue: dateOfBirthMaxValue,
    message: 'Please write a valid date of birth.',
  },
  nameDate: {
    rendered: true,
    required: false,
    nameDateDayPlaceholder: 'Date',
    nameDateMonthPlaceholder: 'Month',
    nameDateLabel: 'Date of name date',
    nameDateDayValue: '',
    nameDateMonthValue: '',
    errorMessage: 'Please select a valid name day. First select the month.',
  },
  singleConsent: {
    rendered: true,
    required: false,
    label: 'Consent',
  },
  smsConsent: {
    rendered: true,
    value: false,
  },
  viberConsent: {
    rendered: true,
    value: false,
  },
  emailConsent: {
    rendered: true,
    value: false,
  },
  multiConsent: {
    rendered: true,
    required: false,
    label: 'With this choice you select all the options (SMS, Viber, email).',
    value: false,
  },
  message: '',
  showMessage: false,
};

export const DemoRegistrationFormReducer = createReducer(
  initialState,
  on(Token, (state, { token }) => ({
    ...state,
    token,
  })),
  on(InitMobileNumber, (state, { mobileNumber }) => ({
    ...state,
    mobileNumber: { ...state.mobileNumber, value: mobileNumber },
  })),
  on(InitQCCode, (state, { qcCode }) => ({
    ...state,
    qcCode,
  })),
  on(UpdatePersonData, (state) => ({
    ...state,
    message: '',
    showMessage: false,
  })),
  on(UpdatePersonDataSuccess, (state) => ({
    ...state,
    message: 'Συγχαρητήρια, μόλις ολοκληρώσατε επιτυχώς την εγγραφή σας',
    showMessage: true,
  })),
  on(UpdatePersonDataFailed, (state) => ({
    ...state,
    message: 'Υπήρξε ένα πρόβλημα κατά την εγγραφή σας',
    showMessage: true,
  }))
);
