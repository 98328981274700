import { Component } from '@angular/core';
import {
  CreatePersonData,
  OtpValidationFacadeService,
  PersonDetailsFacadeService,
} from '@qivos/qivoscloud-ion-components';
import { OtpValidation } from '@qivos/qivoscloud-ion-components/lib/models/otp-validation.model';
import { PersonDetailsForm } from '@qivos/qivoscloud-ion-components/lib/models/person-details.model';
import { Consent, Person, QivosCloudService } from '@qivos/qivoscloud-ng-api';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(
    public facade: OtpValidationFacadeService,
    public PersonFacade: PersonDetailsFacadeService,
    public qcService: QivosCloudService
  ) {}

  person = new Person();
  consentList: Consent[] = [];

  otpValidationConfig: OtpValidation = {
    mobileNumber: {
      rendered: true,
      required: false,
      label: '',
      placeholder: 'Mobile Number',
      value: '',
      validation: /^69[0-9]*\d*$/,
      message: '',
      disabled: false,
    },
    otp: {
      rendered: true,
      required: false,
      label: '',
      placeholder: 'Otp',
      value: '',
      validation: /^\d{6}$/,
      message: 'Το OTP δεν είναι σωστό',
    },
    account: 'demo',
    countryCode: 'gr',
    otpProfileCode: 'REFER_A_FRIEND_OTP',
    schemaCode: '1111',
    type: 'CUSTOMER_REGISTRATION',
    languageCode: 'el',
    sendOtpTextBtn: 'Send Otp',
    validateOtpTextBtn: 'Submit',
    showRegistrationForm: false,
    btnTextColor: 'white',
    btnColor: 'gray',
    jwtToken: '',
  };

  personDetails: PersonDetailsForm = {
    mobileNumber: {
      rendered: true,
      required: false,
      label: '',
      placeholder: '',
      value: '',
      validation: /^69[0-9]*\d*$/,
      message: '',
      disabled: true,
    },
    gender: {
      rendered: true,
      required: false,
      label: 'Προσφώνηση',
      maleGenderLabel: 'Κύριος',
      femaleGenderLabel: 'Κυρία',
      otherGenderLabel: 'Άλλο',
      value: { Name: '', Value: '' },
    },
    firstName: {
      rendered: true,
      required: false,
      disabled: false,
      label: 'Όνομα',
      placeholder: 'Πληκτρολόγησε το όνομά σου',
      value: '',
      validation: /^[α-ωίϊΐόάέύϋΰήώΑ-ΩΆΈΊΌΎΏΉa-zA-Zs.-]+$/,
      message: 'Τα στοιχεία που καταχωρήσατε δεν είναι σωστά',
    },
    lastName: {
      rendered: true,
      required: false,
      disabled: false,
      label: 'Επώνυμο',
      placeholder: 'Πληκτρολόγησε το επώνυμό σου',
      value: '',
      validation: /^[α-ωίϊΐόάέύϋΰήώΑ-ΩΆΈΊΌΎΏΉa-zA-Zs.-]+$/,
      message: 'Τα στοιχεία που καταχωρήσατε δεν είναι σωστά',
    },
    email: {
      rendered: true,
      required: false,
      label: 'Email',
      placeholder: 'Πληκτρολόγησε το email σου',
      value: '',
      validation:
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+(@[A-Za-z0-9.-]{2,})+\.[A-Za-z]{2,}/,
      message: 'Τα στοιχεία που καταχωρήσατε δεν είναι σωστά',
    },
    region: {
      rendered: true,
      required: false,
      label: 'Νομός',
      placeholder: '',
      value: '',
      message: '',
    },
    town: {
      rendered: true,
      required: false,
      label: 'Πόλη',
      placeholder: '',
      value: '',
    },
    zipCode: {
      rendered: true,
      required: false,
      label: 'Τ.Κ.',
      placeholder: '',
      value: '',
      validation: /^[0-9]{5}$/,
      message: 'Τα στοιχεία που καταχωρήσατε δεν είναι σωστά',
    },
    dateOfBirth: {
      rendered: true,
      required: false,
      label: 'Ημερομηνία γέννησης',
      placeholder: 'Ημερομηνία',
      datePickerDoneButtonLabel: 'OK',
      datePickerCancelButtonLabel: 'Άκυρο',
      value: '',
      minValue: 18,
      maxValue: 0,
      message: 'Τα στοιχεία που καταχωρήσατε δεν είναι σωστά',
    },
    nameDate: {
      rendered: true,
      required: false,
      nameDateDayPlaceholder: 'Ημέρα',
      nameDateMonthPlaceholder: 'Μήνας',
      nameDateLabel: 'Ημερομηνία Ονομαστικής Εορτής',
      nameDateDayValue: '',
      nameDateDayLabel: 'Ημέρα',
      nameDateMonthValue: '',
      nameDateMonthLabel: 'Μήνας',
      errorMessage: 'Πρέπει να επιλέξεις πρώτα μήνα',
    },
    singleConsent: {
      rendered: true,
      required: false,
      label: '',
    },
    smsConsent: {
      rendered: true,
      label: 'Με SMS',
      value: false,
      name: 'SMS ',
    },
    viberConsent: {
      rendered: true,
      label: 'Με instant messages (Viber κλπ)',
      value: false,
      name: 'VIBER ',
    },
    emailConsent: {
      rendered: true,
      label: 'Με Email',
      value: false,
      name: 'EMAIL ',
    },
    multiConsent: {
      rendered: true,
      required: false,
      label: '',
    },
    allConsent: {
      rendered: true,
      label: 'Με όλα τα παραπάνω',
      value: false,
    },
    isPersonalized: false,
    token: '',
  };

  submit() {
    const person = {
      firstName: this.PersonFacade.firstName,
      lastName: this.PersonFacade.lastName,
      languageCode: this.facade.languageCode,
      gender: this.PersonFacade.gender.Value,
      dateOfBirth: this.PersonFacade.dateOfBirth,
      telephoneList: [
        {
          telephoneNumber: this.facade.mobileNumber,
          countryCode: this.facade.countryCode,
          telephoneType: 'MOBILE',
          isPrimary: true,
        },
      ],
      loyaltyMembershipData: [
        {
          schemaCode: '1111',
          registrationCountryCode: this.facade.countryCode,
          category: 'Normal',
          registrationSource: 'REFERRAL',
          registrationStoreCode: '1000',
          loyaltyCardData: {
            type: 'Permanent',
          },
          attributes: [],
        },
      ],
      consentList: this.consentList,
      addressList: [
        {
          countryCode: this.facade.countryCode,
          addressLine2: '',
          town: this.PersonFacade.selectedTown,
          addressType: 'PRIMARY_PERSON_ADDRESS',
        },
      ],
      emailList: [
        {
          emailAddress: this.PersonFacade.email,
          isPrimary: true,
          attributes: [],
        },
      ],
    };

    if (this.PersonFacade.multiConsentsStatus === false) {
      if (this.PersonFacade.smsConsent === true) {
        this.consentList.push(
          new Consent({
            name: 'SMS',
            flag: true,
            metadata: [{ key: 'Retail', value: 'OFFLINE' }],
          })
        );
      }

      if (this.PersonFacade.emailConsent === true) {
        this.consentList.push(
          new Consent({
            name: 'EMAIL',
            flag: true,
            metadata: [{ key: 'Retail', value: 'OFFLINE' }],
          })
        );
      }

      if (this.PersonFacade.viberConsent === true) {
        this.consentList.push(
          new Consent({
            name: 'VIBER',
            flag: true,
            metadata: [{ key: 'Retail', value: 'OFFLINE' }],
          })
        );
      }
    }

    if (this.PersonFacade.multiConsentsStatus === true) {
      this.consentList.push(
        new Consent({
          name: 'SMS',
          flag: true,
          metadata: [{ key: 'Retail', value: 'OFFLINE' }],
        }),
        new Consent({
          name: 'EMAIL',
          flag: true,
          metadata: [{ key: 'Retail', value: 'OFFLINE' }],
        }),
        new Consent({
          name: 'VIBER',
          flag: true,
          metadata: [{ key: 'Retail', value: 'OFFLINE' }],
        })
      );
    }

    this.facade.dispatch(
      CreatePersonData({
        person,
      })
    );
  }

  // register() {
  //   if (this.PersonFacade.email.length > 0) {
  //     this.person.emailList?.push(
  //       new Email({ emailAddress: this.PersonFacade.email, isPrimary: true })
  //     );
  //   }

  //   if (this.PersonFacade.selectedRegionCode.length > 0) {
  //     this.person.addressList?.push(
  //       new Address({
  //         addressLine2: '',
  //         addressType: 'PRIMARY_PERSON_ADDRESS',
  //         town: this.PersonFacade.selectedTown,
  //         regionCode: this.PersonFacade.selectedRegionCode,
  //         countryCode: 'gr',
  //         postCode: this.PersonFacade.zipCode,
  //       })
  //     );
  //   }

  //   if (this.PersonFacade.multiConsentsStatus === false) {
  //     if (this.PersonFacade.smsConsent === true) {
  //       this.person.consentList?.push(
  //         new Consent({
  //           name: 'SMS',
  //           flag: true,
  //         })
  //       );
  //     }

  //     if (this.PersonFacade.emailConsent === true) {
  //       this.person.consentList?.push(
  //         new Consent({
  //           name: 'EMAIL',
  //           flag: true,
  //         })
  //       );
  //     }

  //     if (this.PersonFacade.viberConsent === true) {
  //       this.person.consentList?.push(
  //         new Consent({
  //           name: 'VIBER',
  //           flag: true,
  //         })
  //       );
  //     }
  //   }

  //   if (this.PersonFacade.multiConsentsStatus === true) {
  //     this.person.consentList?.push(
  //       new Consent({
  //         name: 'SMS',
  //         flag: true,
  //       }),
  //       new Consent({
  //         name: 'EMAIL',
  //         flag: true,
  //       }),
  //       new Consent({
  //         name: 'VIBER',
  //         flag: true,
  //       })
  //     );
  //   }

  //   this.person.firstName = this.PersonFacade.firstName;
  //   this.person.lastName = this.PersonFacade.lastName;
  //   this.person.salutationCode = this.PersonFacade.gender.Value;
  //   this.person.gender = this.PersonFacade.gender.Value;
  //   this.person.dateOfBirth = this.PersonFacade.dateOfBirth;
  //   this.person.nameDayDay = this.PersonFacade.nameDateDay;
  //   this.person.nameDayMonth = this.PersonFacade.nameDateMonth;
  //   this.person.QCCode = this.PersonFacade.qcCode;

  //   this.PersonFacade.dispatch(UpdatePersonData({ person: this.person }));

  //   // this.PersonFacade.submitted$.subscribe((submitted) => {
  //   //   if (submitted) {
  //   //     this.PersonFacade.isDataUpdated$.subscribe((isDataUpdated) => {
  //   //       if (isDataUpdated) {
  //   //         window.location.href = 'https://www.google.com';
  //   //       } else {
  //   //         console.log('error');
  //   //       }
  //   //     });
  //   //   }
  //   // });
  // }
}
