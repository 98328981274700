import {
  DemoRegistrationFormReducer,
  DemoRegistrationFormState,
} from './demo-registration-form.reducer';
import { createFeatureSelector } from '@ngrx/store';

export const featureDemoRegistrationFormName = 'DemoRegistrationForm';

export interface DemoRegistrationState {
  demoRegistrationForm: DemoRegistrationFormState;
}

export const moduleDemoRegistrationReducers = {
  demoRegistrationForm: DemoRegistrationFormReducer,
};

export const getDemoRegistrationState =
  createFeatureSelector<DemoRegistrationState>(featureDemoRegistrationFormName);
