import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  AuthByToken,
  AuthByTokenFailed,
  AuthByTokenSuccess,
  UpdatePersonData,
  UpdatePersonDataFailed,
  UpdatePersonDataSuccess,
} from '../actions/demo-registration-form.actions';
import { catchError, of, switchMap } from 'rxjs';
import { QivosCloudService } from '@qivos/qivoscloud-ng-api';

@Injectable()
export class DemoRegistrationFormEffects {
  constructor(private actions$: Actions, public qcService: QivosCloudService) {}

  authByToken$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthByToken),
      switchMap(({ token }) =>
        this.qcService.authByToken(token).pipe(
          switchMap((response: any) => of(AuthByTokenSuccess(response))),
          catchError((error) => of(AuthByTokenFailed(error)))
        )
      )
    )
  );

  updatePersonData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UpdatePersonData),
      switchMap(({ person }) =>
        this.qcService.updatePerson(person).pipe(
          switchMap((response: any) => of(UpdatePersonDataSuccess(response))),
          catchError((error) => of(UpdatePersonDataFailed(error)))
        )
      )
    )
  );
}
