<ion-content [fullscreen]="true">
  <div class="wrapper-form">
    <div *ngIf="!facade.showRegistrationForm">
      <ion-qc-otp-validation
        [otpValidationConfig]="otpValidationConfig"
      ></ion-qc-otp-validation>
    </div>

    <div *ngIf="facade.showRegistrationForm">
      <ion-qc-person-details-form
        [personDetails]="personDetails"
      ></ion-qc-person-details-form>
      <ion-button
        [disabled]="PersonFacade.submitButtonDisabled$ | async"
        (click)="submit()"
        >Submit</ion-button
      >
    </div>

    <div *ngIf="PersonFacade.submitted">
      <ion-label *ngIf="PersonFacade.isDataCreated" class="align-text">
        Συγχαρητήρια, μόλις ολοκληρώσατε επιτυχώς την εγγραφή σας
      </ion-label>
      <ion-label *ngIf="!PersonFacade.isDataCreated" class="align-text">
        Υπήρξε ένα πρόβλημα κατά την εγγραφή σας
      </ion-label>
    </div>
  </div>
</ion-content>
